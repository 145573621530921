export const getColorCharts = (percentage) => {
  if (percentage < 25) {
    return 'green'
  } else if (percentage < 60) {
    return 'yellow'
  } else if (percentage < 90) {
    return 'orange'
  }

  return 'red'
}

export const getTotalBudgetAndSpend = (budgets = [], isMonthly, month = new Date().getMonth()) => {
  // TODO: consider only approved budgets
  let totalBudget = 0
  let totalSpend = 0

  const calculateTotalBudgetAndSpend = (products = []) => {
    products.forEach((product) => {
      totalSpend = totalSpend + (product.actuals?.[`_${month}`]?.cost || 0)
      totalBudget = totalBudget + (product.budgets?.[`_${month}`] || 0)
    })
  }

  budgets.forEach((budget) => {
    if (budget) {
      if (isMonthly) {
        if (budget.hasOwnProperty('departments')) {
          budget.departments?.forEach((department) =>
            department.portfolios?.forEach((portfolio) => calculateTotalBudgetAndSpend(portfolio.products))
          )
        } else if (budget.hasOwnProperty('portfolios')) {
          budget.portfolios?.forEach((portfolio) => calculateTotalBudgetAndSpend(portfolio.products))
        } else if (budget.hasOwnProperty('products')) {
          calculateTotalBudgetAndSpend(budget.products)
        } else {
          calculateTotalBudgetAndSpend([budget])
        }
      } else {
        totalBudget += budget.amount || 0
        totalSpend += budget.usedYTD || 0
      }
    }
  })

  return {
    totalBudget,
    totalSpend,
    percentage: totalBudget ? Math.floor((totalSpend / totalBudget) * 100) || 0 : 0
  }
}

function getCurrentQuarter(date = new Date()) {
  return Math.floor(date.getMonth() / 3 + 1)
}

export const getSpendQuarter = (budgets = []) => {
  const quarter = getCurrentQuarter()
  const startMonth = (quarter - 1) * 3
  const endMonth = startMonth + 2
  let totalSpendQuarter = 0

  budgets.forEach((budget) => {
    if (budget) {
      const departments = budget?.departments || []
      departments.forEach((department) => {
        const portfolios = department.portfolios || []
        portfolios.forEach((portfolio) => {
          const products = portfolio.products || []
          products.forEach((product) => {
            for (let i = startMonth; i <= endMonth; i++) {
              totalSpendQuarter += product.actuals?.[`_${i}`].cost || 0
            }
          })
        })
      })
    }
  })

  return totalSpendQuarter
}

export const getUnallocatedBudget = (budgets = []) => {
  let totalBudget = 0
  let plannedBudget = 0

  const calculatePlannedBudget = (products) => {
    products.forEach((product) => {
      if (product.budgets) {
        plannedBudget += Object.values(product.budgets).reduce((a, b) => a + (b || 0), 0)
      }
    })
  }

  budgets.forEach((budget) => {
    if (budget) {
      if (budget.hasOwnProperty('departments')) {
        totalBudget += budget.amount
        budget.departments?.forEach((department) =>
          department.portfolios?.forEach((portfolio) => calculatePlannedBudget(portfolio.products))
        )
      } else if (budget.hasOwnProperty('portfolios')) {
        totalBudget += budget.amount
        budget.portfolios?.forEach((portfolio) => calculatePlannedBudget(portfolio.products))
      } else if (budget.hasOwnProperty('products')) {
        totalBudget += budget.amount
        calculatePlannedBudget(budget.products)
      } else {
        calculatePlannedBudget([budget])
      }
    }
  })

  return totalBudget - plannedBudget
}

export const getEOYPlannedBudget = (budgets, month = new Date().getMonth()) => {
  let EOYPlanned = 0

  const calculateEOYPlanned = (products) => {
    products.forEach((product) => {
      const budgetCurrentMonth = product.budgets?.[`_${month}`] || 0
      const spendCurrentMonth = product.actuals?.[`_${month}`].cost || 0
      const varianceCurrentMonth = budgetCurrentMonth - spendCurrentMonth
      EOYPlanned += varianceCurrentMonth

      for (let i = month + 1; i < 12; i++) {
        EOYPlanned += product.budgets?.[`_${i}`] || 0
      }
    })
  }

  budgets.forEach((budget) => {
    if (budget) {
      if (budget.hasOwnProperty('departments')) {
        budget.departments?.forEach((department) =>
          department.portfolios?.forEach((portfolio) => calculateEOYPlanned(portfolio.products))
        )
      } else if (budget.hasOwnProperty('portfolios')) {
        budget.portfolios?.forEach((portfolio) => calculateEOYPlanned(portfolio.products))
      } else if (budget.hasOwnProperty('products')) {
        calculateEOYPlanned(budget.products)
      } else {
        calculateEOYPlanned([budget])
      }
    }
  })

  return EOYPlanned
}
