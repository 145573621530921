import { Card, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { getFormatTimestamp } from '../helpers/getFormatTimestamp'
import { getInvoicePeriods } from '../helpers/getInvoicePeriods'
import { useContext, useEffect, useRef, useState } from 'react'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import LoadingSpinner from 'components/common/LoadingSpinner'
import InvoiceForm from './InvoiceForm'
import createInvoiceUseCase from '../use-cases/create-invoice-use-case'
import AppContext from 'context/AppContext'

const periodInvoiceOptions = getInvoicePeriods().map((period) => ({
  value: new Date(period.split('-')[0]).getMonth(),
  label: period
}))

const ARInvoice = ({ actuals = [] }) => {
  const invoiceFormRef = useRef()
  const [items, setItems] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState(periodInvoiceOptions[0]?.value)
  const { repoFactory } = useContext(AppContext)

  const [totalData, setTotalData] = useState({
    usage: 0,
    credit: 0,
    tax: 0,
    total: 0
  })

  useEffect(() => {
    filterActualsByPeriod(actuals)
  }, [actuals, selectedPeriod])

  const filterActualsByPeriod = (actuals) => {
    const actualsMap = {}

    actuals.forEach((actual) => {
      const { element1, element4, values } = actual

      if (actualsMap.hasOwnProperty(element1)) {
        actualsMap[element1].amount += values[`_${selectedPeriod}`]?.cost || 0
        actualsMap[element1].credit += values[`_${selectedPeriod}`]?.credit || 0
        actualsMap[element1].tax += values[`_${selectedPeriod}`]?.tax || 0
      } else {
        actualsMap[element1] = {
          element1: element1,
          element4: element4,
          dateRange: periodInvoiceOptions.find((option) => option.value === selectedPeriod).label,
          amount: values[`_${selectedPeriod}`]?.cost,
          credit: values[`_${selectedPeriod}`]?.credit,
          tax: values[`_${selectedPeriod}`]?.tax || 0
        }
      }
    })

    setItems(Object.values(actualsMap))
  }

  useEffect(() => {
    let usage = 0
    let credit = 0
    let tax = 0
    items.forEach((item) => {
      usage += item.amount || 0
      credit += item.credit || 0
      tax += item.tax || 0
    })

    setTotalData({
      usage,
      credit,
      tax,
      total: usage + tax + credit
    })
  }, [items])

  const getInvoiceData = () => {
    const invoiceForm = invoiceFormRef.current

    if (invoiceForm) {
      invoiceForm.submitForm()
      if (invoiceForm.isValid) {
        const { vendor, internal, purchaseOrder, invoiceNumber } = invoiceForm.values

        const breakdown = items.map((item) => ({
          amount: item.amount,
          dateRange: item.dateRange,
          element1: item.element1,
          element4: item.element4
        }))

        const { usage, credit, tax, total } = totalData

        return {
          breakdown,
          credit,
          internal,
          invoiceNumber,
          month: selectedPeriod,
          purchaseOrder,
          tax,
          total,
          type: '',
          usage,
          year: new Date().getFullYear(),
          vendor
        }
      }
    }

    return null
  }

  const createInvoice = async () => {
    const invoiceData = getInvoiceData()
    if (invoiceData) {
      setIsLoading(true)
      createInvoiceUseCase(
        {
          invoice: invoiceData
        },
        {
          monthCloseRepo: repoFactory.monthCloseRepo(),
          observer: {
            success: (response) => {
              const invoiceForm = invoiceFormRef.current

              if (invoiceForm) {
                invoiceForm.resetForm()
              }

              createCsvFile(invoiceData)
              setIsLoading(false)
            },
            error: () => {
              setIsLoading(false)
            }
          }
        }
      )
    }
  }

  const createCsvFile = (invoiceData) => {
    let csvContent = `
    Vendor, ${invoiceData.vendor},
    Invoice Number,${invoiceData.invoiceNumber},
    Invoice Date,${periodInvoiceOptions.find((option) => option.value === selectedPeriod).label},
    Purchase Order,${invoiceData.purchaseOrder},
    Internal,${invoiceData.internal},
    ,,
    Business Unit,Billing Period,Amount,
    `

    invoiceData.breakdown.forEach((item) => {
      csvContent += `${item.element1},${item.dateRange},"${dollarPriceFormatter(item.amount)}",
      `
    })

    csvContent += `,Usage,"${dollarPriceFormatter(invoiceData.usage)}"
      ,Credit,"${dollarPriceFormatter(invoiceData.credit)}"
      ,Tax,"${dollarPriceFormatter(invoiceData.tax)}"
      ,Total,"${dollarPriceFormatter(invoiceData.total)}"
    `

    const blob = new Blob([csvContent], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)

    const filename = 'AR_' + getFormatTimestamp() + '.csv'

    const link = document.createElement('a')
    link.href = url
    link.download = filename
    link.click()
  }

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <h1>AR Invoice</h1>
      <div className="simple-card">
        <Row>
          <Col md={4}>
            <h1 className="mb-1">Period</h1>
            <Select
              placeholder="Date Period"
              options={periodInvoiceOptions}
              defaultValue={periodInvoiceOptions[0]}
              value={periodInvoiceOptions.find((option) => option.value === selectedPeriod)}
              onChange={(option) => setSelectedPeriod(option.value)}
              isClearable
            />
          </Col>
        </Row>
      </div>

      <div className="simple-card mt-4">
        <Card>
          <Card.Body>
            <InvoiceForm formEl={invoiceFormRef} />
          </Card.Body>
        </Card>

        {items?.length ? (
          <Card className="mt-4">
            <Card.Body>
              <table className="table ">
                <thead>
                  <tr>
                    <th>Business Unit</th>
                    <th>Date Range</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => (
                    <tr key={`row-${index}`}>
                      <td>{item.element1}</td>
                      <td>{item.dateRange}</td>
                      <td>{dollarPriceFormatter(item.amount)}</td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan={2} className="text-end">
                      Usage
                    </td>
                    <td>{dollarPriceFormatter(totalData.usage)}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="text-end">
                      Credit
                    </td>
                    <td>{dollarPriceFormatter(totalData.credit)}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="text-end">
                      Tax
                    </td>
                    <td>{dollarPriceFormatter(totalData.tax)}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} className="text-end">
                      Total
                    </td>
                    <td>{dollarPriceFormatter(totalData.total)}</td>
                  </tr>
                </tbody>
              </table>
              <div className="text-center">
                <button className="btn btn-primary" onClick={createInvoice}>
                  Create File
                </button>
              </div>
            </Card.Body>
          </Card>
        ) : null}
      </div>
    </>
  )
}
export default ARInvoice
