import { useFormik } from 'formik'
import { useState } from 'react'
import { Alert, Card, Form } from 'react-bootstrap'

import uploadSor from 'Admin/Configuration/use-cases/upload-sor-use-case'

const BudgetFile = ({ repoFactory, selectedYear, setIsLoading }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const formik = useFormik({
    initialValues: {
      file: null
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {}
  })

  const uploadFile = async () => {
    await formik.submitForm()
    if (formik.isValid) {
      setIsLoading(true)
      const formData = new FormData()
      formData.append('file', formik.values.file)

      const fileContent = await readFileAsBase64(formik.values.file)

      uploadSor(
        {
          token: localStorage.getItem('authToken'),
          fileName: formik.values.file.name,
          fileContent: fileContent.split(',')[1],
          fileType: formik.values.file.type
        },
        {
          sorRepo: repoFactory.sorRepo(),
          observer: {
            error: (e) => {
              setIsLoading(false)
              setError(error)
            },
            success: () => {
              formik.resetForm()
              setSuccess(true)
              setIsLoading(false)
              setError(null)
            }
          }
        }
      )
    }
  }

  return (
    <div>
      {error ? <div className="alert alert-danger">There was an error, please try again.</div> : null}
      <Card>
        <Card.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>File Upload</Form.Label>
            {success && (
              <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
                The file was uploaded successfully.
              </Alert>
            )}
            <Form.Control
              type="file"
              onChange={async (e) => {
                const file = e.target.files[0]
                await formik.setFieldValue('file', file)
                await formik.setFieldTouched('file', true, true)
              }}
            />
            <Form.Text className="text-muted">
              Make sure your file adhere to{' '}
              <a target="_blank" href="https://docs.finopscenter.com/v1/docs/ChartBudgetFile">
                standards
              </a>
              .
            </Form.Text>
            {formik.errors.file && formik.touched.file && <div className="text-error">{formik.errors.file}</div>}
          </Form.Group>
          <button className="btn btn-primary" onClick={uploadFile} disabled={!formik.isValid}>
            Upload File
          </button>
        </Card.Body>
      </Card>
    </div>
  )
}

export default BudgetFile

const validate = (values) => {
  const errors = {}
  const { file } = values

  if (!file) {
    errors.file = 'Please select a file to upload.'
  } else {
    if (!file.name.endsWith('.csv')) {
      errors.file = 'Invalid file format. Please upload a CSV file.'
    } else if (file.size > 1024 * 1024) {
      errors.file = 'The file is too large. Maximum allowed size is 1MB.'
    }
  }

  return errors
}

const readFileAsBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })
}
