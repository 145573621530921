import {
  getColorCharts,
  getEOYPlannedBudget,
  getTotalBudgetAndSpend,
  getUnallocatedBudget
} from 'Budgets/helpers/functions'
import CircularProgressbar from 'components/common/CircularProgressbar/CircularProgressbar'
import dollarPriceFormatter from 'formatters/dollar_price_formatter'
import useElementNames from 'hooks/useElementNames'
import { Button, Card } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const currentMonth = new Date().getMonth()

const DepartmentCard = ({ businessUnitName, department, isMonthly, selectedDepartment, setSelectedDepartment }) => {
  const elementNames = useElementNames()
  const { percentage, totalBudget, totalSpend } = getTotalBudgetAndSpend(department.portfolios, isMonthly)
  const isSelected = selectedDepartment?.id === department.id

  const lastMonthData = getTotalBudgetAndSpend(department.portfolios, true, currentMonth - 1)
  const color = getColorCharts(percentage)
  const variance = totalSpend - totalBudget

  return (
    <Card className={`card-budget ${isSelected ? 'selected-card' : ''} mb-3`}>
      <Card.Body>
        <div className="department-header">
          <div>
            <Card.Subtitle>{elementNames.element1}</Card.Subtitle>
            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{businessUnitName}</Tooltip>}>
              <h5 className="text-ellipsis">{businessUnitName}</h5>
            </OverlayTrigger>
          </div>
          <div className="text-end">
            <Card.Subtitle>{elementNames.element2}</Card.Subtitle>
            <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-right`}>{department.name}</Tooltip>}>
              <h5 className="text-ellipsis">{department.name}</h5>
            </OverlayTrigger>
          </div>
        </div>

        <Card className={`card-outline-${color} mb-3`}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <CircularProgressbar radio="50" percent={percentage} color={color} />
            <div className="text-end">
              <Card.Subtitle>Spend</Card.Subtitle>
              <h2>{dollarPriceFormatter(totalSpend)}</h2>
              <Card.Subtitle>Planned</Card.Subtitle>
              <h2>{dollarPriceFormatter(totalBudget)}</h2>
            </div>
          </Card.Body>
        </Card>

        <Card className={`card-outline-${color} mb-3`}>
          <Card.Body className="d-flex justify-content-between align-items-center">
            <div>
              <h5 className="text-gray mb-1">Variance in spend</h5>
              <h2>{dollarPriceFormatter(variance)}</h2>
            </div>
            <div className="text-end">
              {isMonthly ? (
                <>
                  <Card.Subtitle>Spend Last Month</Card.Subtitle>
                  <h4>{dollarPriceFormatter(lastMonthData.totalSpend)}</h4>
                </>
              ) : (
                <>
                  <Card.Subtitle>Unallocated Budget</Card.Subtitle>
                  <h4>{dollarPriceFormatter(getUnallocatedBudget(department.portfolios))}</h4>
                  <Card.Subtitle>EOY Planned</Card.Subtitle>
                  <h4>{dollarPriceFormatter(getEOYPlannedBudget(department.portfolios))}</h4>
                </>
              )}
            </div>
          </Card.Body>
        </Card>

        <div className="text-center mt-4 mb-3">
          <Button
            disabled={isSelected}
            variant={isSelected ? 'secondary' : 'primary'}
            onClick={() => setSelectedDepartment(department)}
          >
            Details
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}
export default DepartmentCard
